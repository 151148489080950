<template>
	<div class="bid-item-component">
		<v-row no-gutters class="bid-item-box bid-count-total d-flex align-center">
			<v-col cols="7" class="pa-0">
				<p class="heading-mf15">Subtotal</p>
			</v-col>
			<v-col cols="5" class="pa-0 mb-3 d-flex align-center justify-end">
				<p class="heading-sf15">{{ this.$options.filters.currency(subTotal) }}</p>
			</v-col>
			<v-col cols="7" class="pa-0">
				<p class="heading-mf15">Shipping</p>
			</v-col>
			<v-col cols="5" class="pa-0 mb-3 d-flex align-center justify-end">
				<p class="heading-sf15">{{ this.$options.filters.currency(shipping) }}</p>
			</v-col>
			<v-col cols="7" class="pa-0">
				<p class="heading-mf15">Service Fee</p>
			</v-col>
			<v-col cols="5" class="pa-0 mb-3 d-flex align-center justify-end">
				<p class="heading-sf15">{{ this.$options.filters.currency(serviceFee) }}</p>
			</v-col>
			<v-col cols="7" class="pa-0">
				<p class="heading-mf15">Sales Tax</p>
			</v-col>
			<v-col cols="5" class="pa-0 d-flex align-center justify-end">
				<p class="heading-sf15">{{ this.$options.filters.currency(salesTax) }}</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		value: Object
	},

	computed: {
		subTotal() {
			if (this.value.sub_total > 0) {
				return this.value.sub_total.toFixed(2);
			} else {
				return this.value.sub_total;
			}
		},

		shipping() {
			if (this.value.shipping > 0) {
				return this.value.shipping.toFixed(2);
			} else {
				return this.value.shipping;
			}
		},

		serviceFee() {
			if (this.value.service_fee > 0) {
				return this.value.service_fee.toFixed(2);
			} else {
				return this.value.service_fee;
			}
		},

		salesTax() {
			if (this.value.sales_tax > 0) {
				return this.value.sales_tax.toFixed(2);
			} else {
				return this.value.sales_tax;
			}
		}
	}
};
</script>